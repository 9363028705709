body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.pre-material {
  width: 97px;
  height: 120px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 4px 4px 2px -3px rgba(0, 0, 0, 0.25);
  margin: 15px 5px 0px 5px;
  font-family: Pretendard;
  font-size: 8px;
  font-weight: 400;
  text-align: center;
}
.custom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 150px;
  height: 8px;
  background: #dfd9fc;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.custom-slider:hover {
  opacity: 1;
}

.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: #5c10b5;
  cursor: pointer;
  border-radius: 50%;
}

.custom-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: #5c10b5;
  cursor: pointer;
  border-radius: 50%;
}

.custom-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: #5c10b5;
  cursor: pointer;
  border-radius: 50%;
}
.slider-container {
  display: grid;
  grid-template-columns: repeat(2, 150px);
  grid-template-rows: repeat(5, auto);
  gap: 10px;
  justify-content: center;
  margin: 12 auto;
}

.slider-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
}

.custom-slider {
  width: 100%;
}

.custom-modal-content {
  background-color: rgba(0, 0, 0, 1);
  box-shadow: none;
}

.custom-modal-dialog {
  max-width: 500px;
  border-color: #dfd9fc;
}

.custom-modal-body {
  background-color: #dfd9fc;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  border-color: #dfd9fc;
}

.custom-modal-body .capture-and-upload-button {
  margin-top: 15px;
}

.scrollable-container::-webkit-scrollbar {
  display: none;
}
.text-1 {
  font-family: "Pretendard";
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.text-bold-1 {
  font-family: "Pretendard";
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
.text-bold-2 {
  font-family: "Pretendard";
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}
